/*!
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

@use "../../../stylesheets/vars.scss";

.accept-field {
    label.warning {
        color: vars.$danger;
    }

    .skip {
        color: vars.$success;
        font-style: italic;
    }

    // Don't touch the margin + padding: they also control if the user can click on the checkbox instead of label
    .level_2 {
        margin-left: 24px;
        padding: 0;
        label {
            margin-top: 0;
        }
    }
}
