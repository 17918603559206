/*!
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

@use "../../../stylesheets/vars.scss";
//
section.table-summary {
    font-size: 1.2em;
    margin-top: 20px;
    width: 100%;

    td {
        padding: 10px;
    }

    td:not(:first-child):not(:last-child) {
        &.light {
            border-right: 1px solid vars.$light-grey;
        }
        &.dark {
            border-right: 1px solid vars.$dark-grey;
        }
    }

    .label {
        font-weight: bold;
        &.light {
            background-color: vars.$light-primary;
            border-right: solid 2px vars.$primary;
        }
        &.dark {
            background-color: vars.$darker-primary;
            border-right: solid 2px vars.$light-primary;
        }
    }
    .value {
        &.light {
            background-color: vars.$light-grey;
        }
        &.dark {
            background-color: vars.$dark-grey;
        }
    }
}
