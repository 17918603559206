// Colors
$primary: #0077cc;
$darker-primary: #0077cc;
$lighter-primary: #a8d9e6;
$light-primary: #cbe7fb;
$lightest-primary: #fcfdff;

$accent: #274b72;

$danger: #bd271e;
$light-danger: #ffcdc6;
$lightest-danger: #ffddd6;

$success: #00bfb3;
$light-success: #d0ffd9;
$lightest-success: #e5ffed;

$warning: #fec514;
$light-warning: #ffe4ab;

$background: #f9f9f9;

$medium-grey: #a6b6be;
$dark-grey: #565656;
$light-grey: #eff2f3;
$lighter-grey: #d9d9d8;
$lightest-grey: #fbfbfb;

$hover: #f9fcfd;

$gold: #d4af37;
$light-gold: #fdf6d4;
$lightest-gold: #fdfde3;

$table-odd-even: #fbfbfb;

// borders
$border: rgba(black, 0.1);
$border-light: rgba(white, 0.2);

// background color
$pricelist-background-color: #1d1d1b;
$pricelist-background: #231f20;

$pricelist-table-container: #00000099;

$badge-i-kind: #ffc100;
$badge-i-kind-border: 1px solid #ffdc79;
$badge-c-kind: #ff000e;
$badge-c-kind-border: 1px solid #fd7178;
$badge-s-kind: #b600ff;
$badge-s-kind-border: 1px solid #d878ff;
$badge-h-kind: #5aa419;
$badge-h-kind-border: 1px solid #b9fd7d;
$badge-letter: #ffffff;

$legend-text-color: #ffffff;
$description-color: #f5d186;

// Sizes
$br: 3px;

// Screen
$medium: 1680px;

// Z-index
$z-index: (
    "sync": 900,
    "flash": 1020,
    "explain": 1010,
);
