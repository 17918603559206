.panelInfo:hover {
    cursor: pointer;
    transition: border 0.3s;
}
.panelInfo {
    border: 1px solid transparent;
}
.panelInfoReadOnly {
    border: 1px solid transparent;
}
