/*
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

.paginator {
    margin: 14px 0;
}

.euiPagination {
    height: 32px; // to align with the rows-per-page selector.
}

/* Style "rows per page" text */
.euiButtonEmpty__text {
    font-size: 0.9em;
}

.mini-paginator {
    margin-top: -9px !important;
    margin-bottom: 0px !important;
}
