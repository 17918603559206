body {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
body::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}
