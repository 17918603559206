/*!
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

@use "../../../stylesheets/vars.scss";

.bool-field {
    .euiCheckbox .euiCheckbox__input ~ .euiCheckbox__label {
        z-index: unset;
    }

    input[type="checkbox"] {
        display: none;
    }

    label {
        display: inline-block;
        margin: 3px 0;
        &.info {
            margin-left: 10px;
            cursor: pointer;
        }
    }

    input[type="checkbox"] + label span {
        font-size: 11px;
        background-color: white;
        border: 1px solid vars.$primary;
        border-radius: 3px;
        padding: 1px 2px;
        margin: 2px 0;
        cursor: pointer;
        i {
            color: transparent;
            padding: 0 1px;
        }
    }

    input[type="checkbox"]:disabled + label span {
        border: 1px solid darken(vars.$medium-grey, 5%);
        background-color: vars.$light-grey;
        cursor: not-allowed;
    }

    input[type="checkbox"]:checked + label span {
        border: 2px solid vars.$primary;
        border-radius: 2px;
        box-shadow: none;
        padding: 0;
        i {
            background-color: vars.$primary;
            color: white;
            padding: 3px 2px;
        }
    }

    input[type="checkbox"]:checked:disabled + label span {
        border: 3px solid vars.$medium-grey;
        cursor: default;
        i {
            background-color: vars.$medium-grey;
        }
    }
}
