/*!
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

@use "sass:color";

.table-preferences-icon-bar {
    padding: 5px 0 5px 0;
    margin: 0 0 5px 0;
    font-size: larger;
    .icon-right {
        float: right;
    }
    .table-name {
        font-weight: bold;
        font-size: larger;
    }

    span {
        margin: 10px 5px 10px 0;
    }
    i.fa-cog {
        &.active {
            color: red;
        }
    }
}
div.advanced-search-container {
    padding: 0 0 10px 0;
}

.preferences {
    h1,
    h2 {
        margin-top: 10px;
    }
    label {
        display: inline-block;
        margin: 5px 10px 10px 0;
    }
    input {
        margin-top: -4px;
    }
    b {
        // styles the arrows of numeric input
        margin-top: 4px;
        margin-left: 1px;
    }
}

table.orders-table {
    width: 100%;
    word-break: break-all;

    caption {
        font-weight: bold;
        font-variant: small-caps;
        font-size: larger;
    }
    td {
        text-align: left;
        vertical-align: middle;
    }
    th {
        text-align: left;
        vertical-align: middle;
    }
    th:first-child {
        border-radius: 10px 0 0 0;
    }
    th:last-child {
        border-radius: 0 10px 0 0;
        border-right: none;
    }
    thead {
        tr.column-ids {
            th {
                padding: 5px 5px 10px 5px;
                background-color: #38a5f8;
                //background-color: vars.$primary;
                color: white;
            }
        }
        span {
            font-weight: bold;
            font-size: larger;
            background-color: #38a5f8;
            //color: vars.$medium-grey;
            padding: 7px 2px 7px 0;
        }
        input {
            font-size: 14px;
        }
        tr.filters {
            th {
                border-right: none;
                border-left: none;

                //color: vars.$primary;
                input {
                    width: 99%;
                    height: 30px;
                    border: 0;
                }
                button.dropdown-button {
                    cursor: pointer;
                    border: 0;
                    background: transparent;
                    outline: none;

                    i.fa {
                        margin-top: 6px;
                        color: #38a5f8;
                        //color: vars.$primary;
                        float: left;
                        font-size: 18px;
                        &.active {
                            //color: vars.$dark-grey;
                        }
                    }
                }
            }
        }
    }
    tbody {
        tr {
            &:hover {
                &.light {
                    background-color: #f2f5f7;
                }
                &.dark {
                    background-color: #282932;
                }
            }
        }
        td {
            word-break: break-word;
            word-wrap: break-word;
            padding: 15px 5px;

            pre {
                white-space: pre-wrap;
            }
        }
        td.highlight i.fa-caret-right {
            font-size: x-large;
        }
        td.table_name {
            font-weight: bold;
            width: 105px;
        }
        td.id,
        td.customer_order_id {
            width: 100px;
        }
        td.Info {
            width: 400px;
        }
        td.completed_at,
        td.created_at {
            width: 125px;
        }
        td.total {
            font-size: 1.4rem;
            font-weight: bold;
            text-align: right;
            width: 150px;
        }
        td.actions {
            cursor: pointer;
            text-align: right;
            font-size: 18px;
            position: relative;
        }
    }
}

.no-results {
    font-weight: bold;
    font-variant: small-caps;
    margin: 0 0 0 2em;
    font-size: large;
}
.pagination {
    margin: 8px 0 10px 0;
}

#filter_headers_info {
    cursor: pointer;
}
