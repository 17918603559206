/*!
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
@use "../stylesheets/vars.scss";

.explain-container {
    position: fixed;
    top: 0;
    right: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background: white;
    width: 435px;
    z-index: map-get(vars.$z-index, explain);
    height: 100%;
    transition: left 325ms ease-in-out;

    &.hide {
        transition: left 250ms ease-in-out;
        left: -440px;
    }

    .container {
        height: 100%;
        overflow: auto;
    }

    p {
        margin-top: 15px;
    }
    h1,
    h2,
    h3 {
        margin-top: 15px;
        color: vars.$primary;
    }
    h1 {
        font-size: 1.25em;
    }
    h2 {
        font-size: 1.2em;
    }
    h3 {
        font-size: 1.15em;
    }

    span {
        font-style: italic;
        &.code {
            font-family: Courier;
        }
    }

    .title {
        position: relative;
        padding: 20px 10px;
        background-color: vars.$primary;
        color: white;
        font-weight: bold;
        font-size: larger;

        a.close {
            position: absolute;
            right: 0;
            top: -4px;
            font-size: 24px;
            padding: 8px;
        }
    }

    .details {
        padding: 10px;
    }
    .details {
        ul {
            list-style-type: circle;
            margin: 15px 0 15px 25px;
        }
    }
}
