/*!
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

//@use "../../stylesheets/mixins.scss";
@use "../../stylesheets/vars";

.user-input-form {
    h3 {
        padding: 20px 0;
        font-size: larger;
        font-weight: bold;
        margin-bottom: 15px;
        margin-top: -20px;
    }

    .form-fieldset {
        //@include mixins.card;
    }

    .form-input {
        margin: 20px 0;

        padding-bottom: 20px;

        em {
            margin-bottom: 1px;
        }
        &.first_lightpath,
        &.second_lightpath,
        p.label {
            text-transform: uppercase;
            font-weight: bold;
            color: vars.$primary;
        }
        &.downgrade_redundant_lp_choice,
        &.service_ports_primary,
        &.service_ports_secondary {
            margin-left: 40px;
        }
        b {
            // styles the arrows of numeric input
            margin-top: 4px;
            margin-left: 1px;
        }
    }

    section.form-errors {
        padding-bottom: 20px;
    }

    .actions {
        display: flex;
        margin-top: 25px;
        .notes {
            display: flex;
            align-items: center;
            flex-grow: 2;
        }
        label {
            margin-right: 10px;
        }
    }

    .buttons {
        width: fit-content;
    }
}

/* EUI specific styling for the forms */
.euiFormRow {
    margin-bottom: 26px; // For service ports this add margin to the label??
}
.euiFormRow__labelWrapper {
    flex-direction: column;
    margin-top: -10px;
}
.euiFormRow__label__large {
    font-size: +1.1em;
    margin-bottom: 0;
}
.euiHorizontalRule {
    border-top: 2px solid vars.$light-primary;
}
