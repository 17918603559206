/*!
 * Copyright 2019-2020 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
@use "../stylesheets/vars.scss";

.action-container {
    button {
        min-width: 4em;
        width: 100%;

        i.fa {
            margin: 2px;
            padding: 0px;
            font-size: 14px;
        }
    }
    .action-dialog {
        display: none;
        position: absolute;
        background-color: white;
        width: auto;
        min-width: 22em;
        z-index: 1;
        &.open {
            display: block;
        }
    }
}
