/*!
 * Copyright 2019-2022 SURF.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *         http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */

@use "../../../stylesheets/vars.scss";

.list-field {
    ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    li {
        margin-top: -15px;
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-left: 8px;

        // We use '>' because we don't want the outer list to influence the inner list
        > section {
            display: flex;
            flex-grow: 20;
            flex-direction: column;

            // We use '>' because we don't want the outer list to influence the inner list
            & > * {
                display: flex;
                flex-direction: column;
                width: 99%;
                margin-left: 5px;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
    div.add-item,
    div.del-item {
        display: flex;
        align-items: center;
        align-self: baseline;
        margin: 5px;
        margin-bottom: 30px;
        cursor: pointer;

        &[id$="0.remove"] {
            margin-top: 15px;
        }

        i.fa-plus {
            color: vars.$success;
            font-size: 30px;
        }

        i.fa-minus {
            color: vars.$danger;
            font-size: 30px;
        }

        label {
            display: block;
            margin-left: 5px;
            cursor: pointer;
        }
        i.disabled {
            cursor: not-allowed;
            color: vars.$light-grey;
        }
    }
    .nest-field + div.del-item[id$="0.remove"] {
        margin-top: 33px;
    }

    div.del-item {
        margin-top: 13px;
    }

    div.add-item {
        align-self: flex-end;
    }

    // Used for nested lists (L2VPN)
    &.outer-list {
        // We use '>' because we don't want the outer list to influence the inner list
        > ul > div.add-item {
            align-self: flex-start;
            padding: 0 2px 0 0;
            align-items: center;
        }
        // We use '>' because we don't want the outer list to influence the inner list
        > ul > li > div.del-item {
            align-self: flex-start;
        }
        // We use '>' because we don't want the outer list to influence the inner list
        > ul > li {
            flex-direction: column;
        }
    }
}
